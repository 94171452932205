import { graphql } from 'gatsby';
import React, { useMemo, useState } from 'react';

import Layout from '../containers/Layout/Layout';
import ArticleThumbnails from '../sections/ArticleThumbnails/ArticleThumbnails';
import Hero from '../sections/Hero/Hero';
import SubscribeCTA from '../sections/SubscribeCTA/SubscribeCTA';
import { findInText } from '../utils';

const Category = ({ pageContext, data: { wpUser } }: any) => {
    const [searchInputValue, setSearchInputValue] = useState("");
    const postsToShow = useMemo(
        () =>
            wpUser.posts.nodes.filter(
                (post: { title: string; content: string }) =>
                    findInText(post.title, searchInputValue) ||
                    findInText(post.content, searchInputValue)
            ),
        [searchInputValue]
    );
    return (
        <Layout {...pageContext}>
            <Hero title={"Author: " + wpUser.name} text={wpUser.description} />
            <ArticleThumbnails
                posts={postsToShow.slice(0, 6)}
                search
                onSearchSubmit={setSearchInputValue}
                searchString={searchInputValue}
            />
            <SubscribeCTA />
            {postsToShow.length > 6 && (
                <ArticleThumbnails posts={postsToShow.slice(6)} />
            )}
        </Layout>
    );
};

export default Category;

export const query = graphql`
    query ($id: String) {
        wpUser(id: { eq: $id }) {
            posts {
                nodes {
                    featuredImage {
                        node {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        aspectRatio: 1.6
                                        sizes: "(min-width: 432px) 432px, 100vw"
                                        transformOptions: {
                                            fit: CONTAIN
                                        }
                                        backgroundColor: "transparent"
                                    )
                                }
                                publicURL
                            }
                            altText
                        }
                    }
                    author {
                        node {
                            name
                            uri
                        }
                    }
                    uri
                    excerpt
                    title
                    content
                    date
                    categories {
                        nodes {
                            name
                            slug
                        }
                    }
                }
            }
            name
        }
    }
`;
